import { Container, Grid } from "@mui/material";
import React from "react";
import { Input, Select } from "../../../../elementos";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getOptions } from "../../../../../store/slices/options";
import ListImages from "../components/Images/ListImages";
import { getInformeFull, updateInformeFull } from "../../../../../store/slices/informes";

const PatioDeJuegos = ({ semana, editable, id }) => {
  const listOptions = useSelector(getOptions);
  const dispatch = useDispatch();
  const informeStore = useSelector(getInformeFull, shallowEqual);

  const handleChange = (name, value, type) => {
    let informeTemp = JSON.stringify(informeStore);
    informeTemp = JSON.parse(informeTemp);
    informeTemp[`${semana}`].patiojuegos[`${name}`] = value;
    dispatch(updateInformeFull(informeTemp));
  };

  return (
    <Container maxWidth="lg" component={"main"}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Select
            defaultValue=""
            value={informeStore[`${semana}`]?.patiojuegos.en_obra ? informeStore[`${semana}`]?.patiojuegos.en_obra : false}
            label="¿Espacio completo en Obra?"
            options={[
              { label: "Si", value: true },
              { label: "No", value: false },
            ]}
            disabled={!editable}
            onChange={(e) => handleChange("en_obra", e, "select")}
          />
        </Grid>
        {informeStore[`${semana}`]?.patiojuegos.cantidad_equipos_totales > 0 && (
          <>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Cantidad de equipos totales"
                variant="standard"
                value={informeStore[`${semana}`]?.patiojuegos.cantidad_equipos_totales}
                restricciones={["soloNumeros"]}
                name="cantidad_equipos_totales"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                type="number"
                label="Cantidad de equipos a reparar o pintar"
                variant="standard"
                value={informeStore[`${semana}`]?.patiojuegos.cantidad_equipos_a_reparar ?? ""}
                restricciones={["soloNumeros"]}
                onChange={(e) => handleChange("cantidad_equipos_a_reparar", e)}
                name="cantidad_equipos_a_reparar"
                min={0}
                max={informeStore[`${semana}`]?.patiojuegos.cantidad_equipos_totales}
                disabled={!editable || informeStore[`${semana}`]?.patiojuegos.cantidad_equipos_totales === 0}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                defaultValue=""
                label="Limpieza de equipos"
                options={listOptions.limpieza}
                value={informeStore[`${semana}`]?.patiojuegos.limpieza_de_equipos ?? ""}
                onChange={(e) => handleChange("limpieza_de_equipos", e)}
                name="limpieza_de_equipos"
                disabled={!editable}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={6}>
          <Select
            defaultValue=""
            label="Estado de Solado"
            options={listOptions.porcentaje}
            value={informeStore[`${semana}`]?.patiojuegos.estado_del_solado ?? ""}
            onChange={(e) => handleChange("estado_del_solado", e)}
            name="estado_del_solado"
            disabled={!editable}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            defaultValue=""
            label="Estado Rejas"
            options={listOptions.porcentaje}
            value={informeStore[`${semana}`]?.patiojuegos.estado_rejas ?? ""}
            onChange={(e) => handleChange("estado_rejas", e)}
            name="estado_rejas"
            disabled={!editable || informeStore[`${semana}`]?.patiojuegos?.cantidad_rejas === "0" || !informeStore[`${semana}`]?.patiojuegos?.cantidad_rejas}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Input
            fullWidth
            type="text"
            label="Observaciones"
            variant="standard"
            multiline
            value={informeStore[`${semana}`]?.patiojuegos.observaciones ?? ""}
            restricciones={[[]]}
            onChange={(e) => handleChange("observaciones", e)}
            name="observaciones"
            inputProps={{ maxLength: 1000 }}
            disabled={!editable}
          />
        </Grid>
        <Grid item xs={12} md={12} flexWrap={"nowrap"}>
          <ListImages imagenes={informeStore[`${semana}`]?.patiojuegos.imagenes} ruta={`${semana}.limpieza.imagenes`} handleChange={handleChange} editable={editable} />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};

export default PatioDeJuegos;
