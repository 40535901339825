import React from 'react';

import '../../assets/css/Divider.css';

export default function Divider() {

    return (
        <div className='Divider'></div>
    )

}